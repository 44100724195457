import React from "react"
import layoutStyles from "./404.module.css"
import logo from "../assets/logo.v1.wide_.png"

const Index = () => {
  return (
    <div className={layoutStyles.layout}>
    	<div className={layoutStyles.content}>
	    	<img
	    		width="200"
	    		height="66"
	    		alt="Nástrojárna Čepelák Logo"
	    		src={logo}
	    	/>

			<h1>Stránka nenalezena</h1>
			<h2 className={layoutStyles.subheading}>Nenašli jste co hledáte? Zkuste přejít zpět na <a className={layoutStyles.link} href="https://www.nastrojarnacepelak.cz">Hlavní stránku</a></h2>
		</div>
    </div>
  )
}

export default Index
